<script setup lang="ts">
export interface IconLogoInitialsProps {
  /**
   * The width of the icon in pixels. Defaults to 48 if not provided.
   */
  width?: number;
  /**
   * The height of the icon in pixels. Defaults to 48 if not provided.
   */
  height?: number;
  /**
   * The title out of which initials will be used.
   */
  title: string;
  /**
   * Determines if the hover effect should be applied to the icon. The icon will scale up and elevate (z-index: 20) on hover when set to true. Optional.
   */
  withHover?: boolean;
}

const props = withDefaults(defineProps<IconLogoInitialsProps>(), {
  width: 48,
  height: 48,
});

const initials = computed(() => {
  const formattedTitle = props.title.replace(/[^\p{L}\p{N}]+/gu, " ").trim();
  const firstLetter = formattedTitle.slice(0, 1);

  if (formattedTitle.split(" ").length === 1)
    return firstLetter;

  const lastName = formattedTitle.split(" ")[1];
  const secondLetter = (lastName && lastName.slice(0, 1)) || formattedTitle.slice(1, 2);
  return `${firstLetter}${secondLetter}`;
});
</script>

<template>
  <svg :width="props.width" :height="props.height" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fill-rule="evenodd">
      <circle fill="var(--base-card-bgSecondaryColor)" cx="24" cy="24" r="24" />
      <text
        font-family="RedHatDisplayRoman-Bold, Red Hat Display"
        font-size="16"
        font-weight="bold"
        fill="var(--base-secondary-color)"
        class="uppercase"
        style="direction: ltr; text-anchor: middle;"
        :class=" { logoInitial__withHover: props.withHover }"
        dy=".35em"
      >
        <tspan x="24" y="24">{{ initials }}</tspan>
      </text>
    </g>
  </svg>
</template>

<style lang="scss">
.logoInitial__withHover {
  @apply hover:z-20 hover:scale-125 origin-center transform transition duration-200;
}
</style>
