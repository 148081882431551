<script setup lang="ts">
/* eslint-disable max-len */
export interface IconNoLogoProps {
  width?: number;
  height?: number;
  fill?: string;
}

const props = withDefaults(defineProps<IconNoLogoProps>(), {
  width: 36,
  height: 36,
  fill: "#A9B7D1",
});
</script>

<template>
  <svg
    viewBox="0 0 36 36"
    :width="props.width"
    :height="props.height"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <circle id="my3osjs9ra" cx="18" cy="18" r="18" />
    </defs>
    <g fill="none" fill-rule="evenodd">
      <mask id="hop18rrr1b" fill="#fff">
        <use xlink:href="#my3osjs9ra" />
      </mask>
      <use xlink:href="#my3osjs9ra" />
      <g mask="url(#hop18rrr1b)" :fill="props.fill">
        <path
          d="M24 11a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H12a2 2 0 0 1-2-2V13a2 2 0 0 1 2-2h12zm-3.002 6.5-.078.007a.499.499 0 0 0-.273.14l-3.71 3.71-2.66-1.773a.5.5 0 0 0-.63.062L11 22v1a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-3.5l-3.777-1.947a.5.5 0 0 0-.304-.046zM14.501 14a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3z"
        />
      </g>
    </g>
  </svg>
</template>
