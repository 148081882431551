<script setup lang="ts">
export interface BaseLogoProps {
  /**
   * URL for the logo image source.
   */
  src?: string;
  /**
   *  Alternative text for the logo image, displayed when the image can't be loaded.
   */
  alt?: string;
  /**
   * Width of the logo. Default is 36 pixels.
   */
  width?: number;
  /**
   *  Height of the logo. Default is 36 pixels.
   */
  height?: number;
  /**
   *  If true, the logo will use its original size. Default is false.
   */
  fullSize?: boolean;
  /**
   *  If true, logo will have hover effect. Default is false.
   */
  withHover?: boolean;
  /**
   *  If true, logo will have no background. Default is false.
   */
  noBackground?: boolean;
  /**
   *  The background color of the icon. If undefined, no background color is applied.
   */
  withBorder?: boolean;
  /**
   *  The border of the icon. Default is false.
   */
  iconBackground?: string;
  /**
   *  If true, the logo will be replaced with initials based on the title. Default is false.
   */
  withInitials?: boolean;
  /**
   *  The title for the logo, used to generate initials when `withInitials` is true.
   */
  title?: string;
  /**
   *  The object-fit property of the logo. Default is 'cover'.
   */
  fit?: "cover" | "contain";
}

const props = withDefaults(defineProps<BaseLogoProps>(), {
  alt: "base logo",
  width: 36,
  height: 36,
  fullSize: false,
  noBackground: false,
  fit: "cover",
});
const fitClass = {
  cover: "object-cover",
  contain: "object-contain",
};
const currentSrc = ref(props.src || "");
const onImageLoadError = () => (currentSrc.value = "");
</script>

<template>
  <div
    v-if="props.iconBackground && currentSrc"
    class="px-1.5 rounded-full flex items-center justify-center"
    :style="{ backgroundColor: props.iconBackground, width: `${props.width}px`, height: `${props.height}px` }"
  >
    <BaseImage
      fallback-src=""
      :src="currentSrc"
      :alt="props.alt"
      :width="props.width - 12"
      :height="props.height - 12"
      :class="[
        { baseLogo: !props.fullSize },
        { baseLogo__withHover: props.withHover },
        { baseLogo__withBorder: props.withBorder },
        { baseLogo__withBackground: !props.noBackground },
        fitClass[props.fit],
      ]"
      @error="onImageLoadError()"
    />
  </div>
  <BaseImage
    v-else-if="currentSrc"
    fallback-src=""
    :src="currentSrc"
    :alt="props.alt"
    :width="props.width"
    :height="props.height"
    :class="[
      { baseLogo: !props.fullSize },
      { baseLogo__withHover: props.withHover },
      { baseLogo__withBorder: props.withBorder },
      { baseLogo__withBackground: !props.noBackground },
      fitClass[props.fit],
    ]"
    @error="onImageLoadError()"
  />

  <IconLogoInitials
    v-else-if="props.title && props.title !== '---' && props.withInitials"
    class="baseLogo__withBackground baseLogo"
    :class="{ 'border-2 border-solid border-[var(--base-card-bgColor)]': props.withBorder }"
    :title="props.title"
    :width="props.width"
    :height="props.height"
    :with-hover="props.withHover"
  />
  <div
    v-else
    class="flex items-center justify-center rounded-full baseLogo__withBackground border-2 border-solid border-[var(--base-card-bgColor)]"
    :style="{ width: `${props.width}px`, height: `${props.height}px` }"
  >
    <IconNoLogo
      fill="var(--base-icon-color)"
      :width="props.width"
      :height="props.height"
      :class="{ baseLogo__withHover: props.withHover }"
    />
  </div>
</template>

<style lang="scss">
.baseLogo {
  @apply z-10 flex-shrink-0 overflow-hidden rounded-full;
}

.baseLogo__withBackground {
  background-color: var(--base-card-bgSecondaryColor);
}

.baseLogo__withBorder {
  @apply border border-solid border-[var(--base-border-color)];
}

.baseLogo__withHover {
  @apply hover:z-20 hover:scale-125 transform transition duration-500;
}
</style>
